import React, {useImperativeHandle, useReducer, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import StepIndicator from '@feature/copy/components/StepIndicator/StepIndicator'
import WizButton from '@component/buttons/WizButton'
import LeaderGuideContainer from '@feature/copy/components/CreateLeaderModal/LeaderGuideContainer/LeaderGuideContainer'
import LeaderSelectKeyContainer, {
    IRegisterLeaderSelectKeyRes,
} from '@feature/copy/components/CreateLeaderModal/LeaderSelectKeyContainer/LeaderSelectKeyContainer'
import LeaderProfileSetUpContainer from '@feature/copy/components/CreateLeaderModal/LeaderProfileSetUpContainer/LeaderProfileSetUpContainer'
import CreateLeaderOptionContainer from '@feature/copy/components/CreateLeaderModal/CreateLeaderOptionContainer/CreateLeaderOptionContainer'
import CreateLeaderReviewContainer from '@feature/copy/components/CreateLeaderModal/CreateLeaderReviewContainer/CreateLeaderReviewContainer'
import CreateLeaderDoneContainer from '@feature/copy/components/CreateLeaderModal/CreateLeaderDoneContainer/CreateLeaderDoneContainer'
import {apiPostCreateLeader} from '@api/copy/leader/create/createLeader'
import useSnackbar from '@hook/useSnackbar'
import {API_NAME_MAX_LENGTH, LEADER_MIN_LENGTH} from '@constant/auth'
import WizContentModal from '@component/modal/WizContentModal'
import {ENGLISH_COPYLEADER_FORBIDDEN_REGEX} from '@constant/reg'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {createLog} from '@util/logs'
import useQueryGetLeaderMyKeys from '@hook/query/portfolio/leaderfolio/useQueryGetLeaderMyKeys'

enum PageSTEP {
    STEP_1,
    STEP_2,
    STEP_3,
    STEP_4,
    STEP_5,
    STEP_6,
}

interface IProps {}

export type CreateLeaderModalState = {
    pageStep: PageSTEP
    selectKey?: IRegisterLeaderSelectKeyRes
    thumbnail: string
    leaderName: string
    leaderMessage: string
    minBalance: number
    copierCount: number
}

const CreateLeaderModal = ({}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {showErrorSnackbar} = useSnackbar()
    const {data: me} = useQueryFetchMe(undefined, false)
    const {refresh} = useQueryGetLeaderMyKeys(undefined, true)

    const initialState: CreateLeaderModalState = {
        pageStep: PageSTEP.STEP_1,
        selectKey: undefined,
        thumbnail: me?.thumbnail,
        leaderName: '',
        leaderMessage: '',
        minBalance: 200,
        copierCount: 10,
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const {pageStep, selectKey, thumbnail, leaderName, leaderMessage, minBalance, copierCount}: CreateLeaderModalState =
        state

    function reducer(state, action) {
        switch (action.type) {
            case 'field_update':
                return {...state, [action.field]: action.value}
            case 'reset':
                return action.payload
            case 'page_back': {
                let nextPage
                switch (state.pageStep) {
                    case PageSTEP.STEP_2:
                        nextPage = PageSTEP.STEP_1
                        break
                    case PageSTEP.STEP_3:
                        nextPage = PageSTEP.STEP_2
                        break
                    case PageSTEP.STEP_4:
                        nextPage = PageSTEP.STEP_3
                        break
                    case PageSTEP.STEP_5:
                        nextPage = PageSTEP.STEP_4
                        break
                    case PageSTEP.STEP_6:
                        nextPage = PageSTEP.STEP_5
                        break
                }

                return {
                    ...state,
                    pageStep: nextPage,
                }
            }
            case 'page_next': {
                let nextPage
                switch (state.pageStep) {
                    case PageSTEP.STEP_1:
                        nextPage = PageSTEP.STEP_2
                        break
                    case PageSTEP.STEP_2:
                        nextPage = PageSTEP.STEP_3
                        break
                    case PageSTEP.STEP_3:
                        nextPage = PageSTEP.STEP_4
                        break
                    case PageSTEP.STEP_4:
                        nextPage = PageSTEP.STEP_5
                        break
                    case PageSTEP.STEP_5:
                        nextPage = PageSTEP.STEP_6
                        break
                }

                return {
                    ...state,
                    pageStep: nextPage,
                }
            }
        }
    }

    const reset = () => dispatch({type: 'reset', payload: initialState})

    useImperativeHandle(ref, () => ({
        show: () => {
            reset()
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    const getTitle = () => {
        if (pageStep === PageSTEP.STEP_2) return t('modal.createLeader.step2.title')
        if (pageStep === PageSTEP.STEP_3) return t('modal.createLeader.step3.title')
        if (pageStep === PageSTEP.STEP_4) return t('modal.createLeader.step4.title')
        if (pageStep === PageSTEP.STEP_5) return t('modal.createLeader.step5.title')
        if (pageStep === PageSTEP.STEP_6) return ''
        else return t('modal.createLeader.step1.title')
    }

    const createLeader = async () => {
        createLog('event', 'Create_Leader_submit', {
            id: selectKey?.leaderKey?.id,
            leaderName,
            minBalance,
            copierCount,
        })
        const {data, error, status} = await apiPostCreateLeader({
            id: selectKey?.leaderKey?.id,
            copy_lead_name: leaderName,
            thumbnail: thumbnail,
            leader_status: leaderMessage,
            min_copy_balance: minBalance,
            max_copier: copierCount,
        })

        if (data) {
            dispatch({type: 'page_next'})
            createLog('event', 'Create_Leader_Complete', {
                id: selectKey?.leaderKey?.id,
                leaderName,
                minBalance,
                copierCount,
            })
            refresh()
        } else {
            showErrorSnackbar(error)
            createLog('error', 'Create_Leader_Error', {
                id: selectKey?.leaderKey?.id,
                leaderName,
                minBalance,
                copierCount,
                error: error?.toString(),
            })
        }
    }

    const onClickClose = () => {
        createLog('event', 'create_leader_modal_close', {step: pageStep})
        modalRef.current.close()
    }
    const onClickBack = () => {
        createLog('event', 'create_leader_modal_back', {step: pageStep})
        dispatch({type: 'page_back'})
    }
    const onClickNext = () => {
        createLog('event', 'create_leader_modal_next', {step: pageStep})
        dispatch({type: 'page_next'})
    }

    return (
        <WizContentModal size={'800'} title={getTitle()} ref={modalRef}>
            {pageStep === PageSTEP.STEP_1 && (
                <div className={'mt-[30px]'}>
                    <div
                        className={
                            'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                        }>
                        <Text className={'text-body1 text-gray01 dark:text-dark_gray01 sm:text-left sm:w-full'}>
                            {t('modal.createLeader.step1.desc')}
                        </Text>
                        <StepIndicator totalStep={5} currentStep={0} className={'sm:w-full sm:flex sm:justify-end'} />
                    </div>

                    <LeaderGuideContainer />

                    <div
                        className={
                            'flex justify-center max-w-[500px] sm:max-w-[100%] mx-auto space-x-[10px] mt-[30px]'
                        }>
                        <WizButton
                            text={t('modal.createLeader.step1.cancel')}
                            buttonType={'border'}
                            className={'max-w-[150px] w-full sm:max-w-[87px]'}
                            onClick={onClickClose}
                        />
                        <WizButton
                            text={t('modal.createLeader.step1.next')}
                            buttonType={'golden'}
                            className={'flex-1'}
                            onClick={onClickNext}
                        />
                    </div>
                </div>
            )}
            {pageStep === PageSTEP.STEP_2 && (
                <div className={'mt-[30px]'}>
                    <div
                        className={
                            'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                        }>
                        <Text className={'text-body1 text-gray01 dark:text-dark_gray01 sm:text-left sm:w-full'}>
                            {t('modal.createLeader.step2.desc')}
                        </Text>
                        <StepIndicator totalStep={5} currentStep={1} className={'sm:w-full sm:flex sm:justify-end'} />
                    </div>

                    <LeaderSelectKeyContainer
                        className={'mt-[30px]'}
                        leader={selectKey?.leaderKey}
                        onChanged={item => {
                            createLog('event', 'create_leader_modal_step2_select_key', {key_id: item?.leaderKey?.id})
                            dispatch({type: 'field_update', field: 'selectKey', value: item})
                        }}
                    />

                    <div
                        className={
                            'flex justify-center max-w-[500px] sm:max-w-[100%] mx-auto space-x-[10px] mt-[30px]'
                        }>
                        <WizButton
                            text={t('modal.createLeader.step2.cancel')}
                            buttonType={'border'}
                            className={'max-w-[150px] w-full sm:max-w-[87px]'}
                            onClick={onClickBack}
                        />
                        <WizButton
                            text={t('modal.createLeader.step2.next')}
                            buttonType={'golden'}
                            className={'flex-1'}
                            disabled={selectKey?.verified !== true}
                            onClick={onClickNext}
                        />
                    </div>
                </div>
            )}
            {pageStep === PageSTEP.STEP_3 && (
                <div className={'mt-[30px]'}>
                    <div
                        className={
                            'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                        }>
                        <Text className={'text-body1 text-gray01 dark:text-dark_gray01 sm:text-left sm:w-full'}>
                            {t('modal.createLeader.step3.desc')}
                        </Text>
                        <StepIndicator totalStep={5} currentStep={2} className={'sm:w-full sm:flex sm:justify-end'} />
                    </div>

                    <LeaderProfileSetUpContainer
                        className={'mt-[30px]'}
                        thumbnail={thumbnail}
                        onChangeThumbnail={url => dispatch({type: 'field_update', field: 'thumbnail', value: url})}
                        leaderName={leaderName}
                        onChangeLeaderName={text => dispatch({type: 'field_update', field: 'leaderName', value: text})}
                        leaderMessage={leaderMessage}
                        onChangeLeaderMessage={text =>
                            dispatch({type: 'field_update', field: 'leaderMessage', value: text})
                        }
                    />

                    <div
                        className={
                            'flex justify-center max-w-[500px] sm:max-w-[100%] mx-auto space-x-[10px] mt-[30px]'
                        }>
                        <WizButton
                            text={t('modal.createLeader.step3.cancel')}
                            buttonType={'border'}
                            className={'max-w-[150px] w-full sm:max-w-[87px]'}
                            onClick={onClickBack}
                        />
                        <WizButton
                            text={t('modal.createLeader.step3.next')}
                            buttonType={'golden'}
                            className={'flex-1'}
                            disabled={
                                !leaderName ||
                                !leaderMessage ||
                                leaderName?.length < LEADER_MIN_LENGTH ||
                                leaderName?.length > API_NAME_MAX_LENGTH ||
                                ENGLISH_COPYLEADER_FORBIDDEN_REGEX.test(leaderName)
                            }
                            onClick={() => {
                                createLog('event', 'create_leader_modal_next', {
                                    step: pageStep,
                                    leaderName,
                                })
                                dispatch({type: 'page_next'})
                            }}
                        />
                    </div>
                </div>
            )}
            {pageStep === PageSTEP.STEP_4 && (
                <div className={'mt-[30px]'}>
                    <div
                        className={
                            'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                        }>
                        <Text className={'text-body1 text-gray01 dark:text-dark_gray01 sm:text-left sm:w-full'}>
                            {t('modal.createLeader.step4.desc')}
                        </Text>
                        <StepIndicator totalStep={5} currentStep={3} className={'sm:w-full sm:flex sm:justify-end'} />
                    </div>

                    <CreateLeaderOptionContainer
                        minBalance={minBalance}
                        onChangeMinBalance={num => dispatch({type: 'field_update', field: 'minBalance', value: num})}
                        className={'mt-[10px]'}
                    />

                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 text-center mt-[30px]'}>
                        {t('modal.createLeader.step4.bottomDesc')}
                    </Text>

                    <div
                        className={
                            'flex justify-center max-w-[500px] sm:max-w-[100%] mx-auto space-x-[10px] mt-[20px]'
                        }>
                        <WizButton
                            text={t('modal.createLeader.step4.back')}
                            buttonType={'border'}
                            className={'max-w-[150px] w-full sm:max-w-[87px]'}
                            onClick={onClickBack}
                        />
                        <WizButton
                            text={t('modal.createLeader.step4.next')}
                            buttonType={'golden'}
                            className={'flex-1'}
                            onClick={() => {
                                createLog('event', 'create_leader_modal_next', {
                                    step: pageStep,
                                    minBalance,
                                    copierCount,
                                })
                                dispatch({type: 'page_next'})
                            }}
                        />
                    </div>
                </div>
            )}
            {pageStep === PageSTEP.STEP_5 && (
                <div className={'mt-[30px]'}>
                    <div
                        className={
                            'mt-[10px] flex justify-between items-center space-x-[10px] sm:flex-col sm:space-x-[0px] sm:space-y-[10px]'
                        }>
                        <Text className={'text-body1 text-gray01 dark:text-dark_gray01 sm:text-left sm:w-full'}>
                            {t('modal.createLeader.step5.desc')}
                        </Text>
                        <StepIndicator totalStep={5} currentStep={4} className={'sm:w-full sm:flex sm:justify-end'} />
                    </div>

                    <CreateLeaderReviewContainer className={'mt-[10px]'} {...state} />

                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 text-center mt-[30px]'}>
                        {t('modal.createLeader.step5.bottomDesc')}
                    </Text>

                    <div
                        className={
                            'flex justify-center max-w-[500px] sm:max-w-[100%] mx-auto space-x-[10px] mt-[20px]'
                        }>
                        <WizButton
                            text={t('modal.createLeader.step5.back')}
                            buttonType={'border'}
                            className={'max-w-[150px] w-full sm:max-w-[87px]'}
                            onClick={onClickBack}
                        />
                        <WizButton
                            text={t('modal.createLeader.step5.next')}
                            buttonType={'golden'}
                            className={'flex-1'}
                            onClick={() => createLeader()}
                        />
                    </div>
                </div>
            )}
            {pageStep === PageSTEP.STEP_6 && (
                <div className={'mt-[30px]'}>
                    <CreateLeaderDoneContainer className={'mt-[30px]'} />
                    <div className={'max-w-[500px] mx-auto w-full space-x-[10px] mt-[30px]'}>
                        <WizButton
                            text={t('modal.createLeader.step6.done')}
                            buttonType={'golden'}
                            className={'w-full'}
                            onClick={onClickClose}
                        />
                    </div>
                </div>
            )}
        </WizContentModal>
    )
}

export default React.forwardRef(CreateLeaderModal)
