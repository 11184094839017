import React, {useRef, useState} from 'react'
import UserThumbnail from '@component/images/UserThumbnail'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import WizTextArea from '@component/textArea/WizTextArea'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import IconEdit from '@svg/common/ic_edit.svg'
import WizDropdown from '@component/dropdowns/WizDropdown'
import {apiUploadImage} from '@api/core/image/uploadImage'
import InputTextCount from '@component/inputs/InputTextCount'
import {COPYLEADER_NAME_MAX_LENGTH, COPYLEADER_STATUS_MESSAGE_MAX_LENGTH, LEADER_MIN_LENGTH} from '@constant/auth'
import {ENGLISH_COPYLEADER_FORBIDDEN_REGEX} from '@constant/reg'
import WizIcon from '@component/images/WizIcon'

interface IProps {
    className?: string
    thumbnail?: string
    onChangeThumbnail?: (image?: string) => void
    leaderName?: string
    onChangeLeaderName?: (name?: string) => void
    leaderMessage?: string
    onChangeLeaderMessage?: (message?: string) => void
}

const LeaderProfileSetUpContainer: React.FC<IProps> = ({
    className,
    thumbnail,
    onChangeThumbnail,
    leaderName,
    onChangeLeaderName,
    leaderMessage,
    onChangeLeaderMessage,
}) => {
    const {t} = useTranslation()
    const imageFileRef = useRef<any>()

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    const uploadImage = async e => {
        const file = e?.target?.files[0]

        if (file) {
            const result = await apiUploadImage(file)
            if (result.success) {
                onChangeThumbnail(result.image_url)
            }
        }

        return
    }

    const onChangedLeaderName = text => {
        onChangeLeaderName(text)

        if (text?.length > COPYLEADER_NAME_MAX_LENGTH) {
            setInputState(InputState.ERROR)
            setInputErrorMessage(t('modal.createLeader.step3.name.error.overMaxLength'))
            return
        }

        if (ENGLISH_COPYLEADER_FORBIDDEN_REGEX.test(text)) {
            setInputState(InputState.ERROR)
            setInputErrorMessage(t('modal.createLeader.step3.name.error.wrongCharacter'))
            return
        }

        setInputState(InputState.DEFAULT)
    }

    const onChangeLeaderMessageStatus = text => {
        const newText =
            text?.length > COPYLEADER_STATUS_MESSAGE_MAX_LENGTH
                ? text.slice(0, COPYLEADER_STATUS_MESSAGE_MAX_LENGTH)
                : text
        onChangeLeaderMessage(newText)
    }

    return (
        <div className={`${className}`}>
            <div className={'flex flex-col items-center'}>
                <div className={'relative'}>
                    <UserThumbnail src={thumbnail} size={80} />
                    <WizDropdown
                        optionList={[
                            {
                                title: t('modal.createLeader.step3.thumbnail.add'),
                                onClick: () => {
                                    imageFileRef && imageFileRef.current.click()
                                },
                            },
                            {
                                title: t('modal.createLeader.step3.thumbnail.remove'),
                                onClick: () => {
                                    onChangeThumbnail('')
                                },
                            },
                        ]}
                        renderView={
                            <WizIcon
                                className={
                                    'absolute bottom-0 right-0 bg-white dark:bg-bg_dark_background border border-gray07 dark:border-dark_gray07 p-[2px] hover:bg-gray06 dark:hover:bg-dark_gray06'
                                }>
                                <IconEdit className={'w-[16px] fill-gray02 dark:fill-dark_gray02'} />
                            </WizIcon>
                        }
                    />
                    <input
                        type={'file'}
                        style={{display: 'none'}}
                        onChange={e => {
                            uploadImage(e)
                            e.target.value = '' // reset for upload same file again
                        }}
                        ref={imageFileRef}
                    />
                </div>
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                    {t('modal.createLeader.step3.thumbnail.desc')}
                </Text>
            </div>

            <Text className={'mt-[30px] text-ti1 text-gray01 dark:text-dark_gray01'}>
                {t('modal.createLeader.step3.name.title')}
            </Text>
            <WizInputText
                className={'mt-[10px]'}
                text={leaderName}
                state={inputState}
                defaultDescription={t('modal.createLeader.step3.name.description')}
                errorDescription={inputErrorMessage}
                onChanged={onChangedLeaderName}
                renderInputRightArea={
                    <InputTextCount
                        text={leaderName}
                        minLength={LEADER_MIN_LENGTH}
                        maxCount={COPYLEADER_NAME_MAX_LENGTH}
                    />
                }
            />
            <Text className={'mt-[30px] text-ti1 text-gray01 dark:text-dark_gray01'}>
                {t('modal.createLeader.step3.message.title')}
            </Text>
            <WizTextArea
                text={leaderMessage}
                className={'mt-[10px] min-h-[112px]'}
                placeholder={t('modal.createLeader.step3.message.placeholder')}
                onChanged={onChangeLeaderMessageStatus}
            />
            <InputTextCount
                text={leaderMessage}
                maxCount={COPYLEADER_STATUS_MESSAGE_MAX_LENGTH}
                className={'text-right'}
                enabled
            />
        </div>
    )
}

export default LeaderProfileSetUpContainer
