import React from 'react'
import SelectLeaderKeyItem from '@feature/copy/components/CreateLeaderModal/LeaderSelectKeyContainer/SelectLeaderKeyItem/SelectLeaderKeyItem'
import {ILeaderRegisterKey} from '@api/copy/leader/getJoinableKey'
import WizList from '@component/list/WizList/WizList'
import useQueryGetLeaderJoinableKey from '@hook/query/portfolio/leaderfolio/useQueryGetLeaderJoinableKey'

export interface IRegisterLeaderSelectKeyRes {
    leaderKey: ILeaderRegisterKey
    verified: boolean
    errorMessage?: string
}

interface IProps {
    className?: string
    leader?: ILeaderRegisterKey
    onChanged?: (key: IRegisterLeaderSelectKeyRes) => void
}

const LeaderSelectKeyContainer: React.FC<IProps> = ({className, leader, onChanged}) => {
    const {data: keys} = useQueryGetLeaderJoinableKey()

    return (
        <div className={`${className}`}>
            <WizList
                className={'space-y-[10px]'}
                data={keys?.sort(item => (item?.lead_state?.includes(1) ? -1 : 1))}
                extractKey={item => item.id}
                renderItem={item => {
                    return <SelectLeaderKeyItem {...item} isSelected={leader?.id === item.id} onChanged={onChanged} />
                }}
            />
        </div>
    )
}

export default LeaderSelectKeyContainer
