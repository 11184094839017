import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import ExchangeTag from '@component/tags/ExchangeTag'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import TagSpan from '@component/Text/TagSpan'

interface IProps {
    className?: string
}

const LeaderGuideContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {data: exchanges} = useQueryGetExchanges()

    return (
        <div className={`mt-[10px] ${className}`}>
            <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                {t('modal.createLeader.step1.requirements.new.title')}
            </Text>
            <div className={'bg-gray09 dark:bg-bg_dark_white02 rounded-[5px] mt-[10px] py-[15px] px-[20px]'}>
                <ul className={'list-disc list-outside pl-[15px]'}>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createLeader.step1.requirements.new.option1')}
                        </TagSpan>
                    </li>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createLeader.step1.requirements.new.option2')}
                        </TagSpan>
                    </li>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createLeader.step1.requirements.new.option3')}
                        </TagSpan>
                    </li>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createLeader.step1.requirements.new.option4')}
                        </TagSpan>
                    </li>
                </ul>
            </div>

            <Text className={'text-ti1 mt-[30px] text-gray01 dark:text-dark_gray01'}>
                {t('modal.createLeader.step1.supportedExchanges.title')}
            </Text>
            <div className={'bg-gray09 dark:bg-bg_dark_white02 rounded-[5px] mt-[10px] py-[15px] px-[20px]'}>
                <div className={'flex justify-between sm:flex-wrap sm:gap-x-[20px] sm:gap-y-[5px]'}>
                    {exchanges
                        ?.filter(item => item.is_copybot_allowed)
                        ?.map(exchange => {
                            return (
                                <div key={exchange.name} className={'flex-1 flex justify-center'}>
                                    <ExchangeTag exchange={exchange.name} />
                                </div>
                            )
                        })}
                </div>
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 text-center mt-[20px]'}>
                    {t('modal.createLeader.step1.supportedExchanges.desc')}
                </Text>
            </div>
        </div>
    )
}

export default LeaderGuideContainer
