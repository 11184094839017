import React, {useMemo} from 'react'
import {CreateLeaderModalState} from '@feature/copy/components/CreateLeaderModal/CreateLeaderModal'
import UserThumbnail from '@component/images/UserThumbnail'
import Text from '@component/Text/Text'
import ExchangeTag from '@component/tags/ExchangeTag'
import {useTranslation} from 'next-i18next'
import useFormatNumber from '@hook/useFormatNumber'
import TagSpan from '@component/Text/TagSpan'

interface IProps extends CreateLeaderModalState {
    className?: string
}

const CreateLeaderReviewContainer: React.FC<IProps> = ({
    className,
    selectKey,
    thumbnail,
    leaderName,
    leaderMessage,
    minBalance,
    copierCount,
}) => {
    const {t} = useTranslation()
    const {displayBalance} = useFormatNumber()

    const renderExchangeMarketText = useMemo(() => {
        switch (selectKey?.leaderKey.exchange) {
            case 'binance':
                return t('modal.createLeader.step5.exchange.market.binance')
            case 'bithumb':
                return t('modal.createLeader.step5.exchange.market.bithumb')
            case 'bitmex':
                return t('modal.createLeader.step5.exchange.market.bitmex')
            case 'bybit':
                return t('modal.createLeader.step5.exchange.market.bybit')
            case 'upbit':
                return t('modal.createLeader.step5.exchange.market.upbit')
        }
    }, [selectKey, t])

    return (
        <div className={`${className}`}>
            <div
                className={
                    'bg-brown_tint01 dark:bg-dark_brown_tint01 border border-transparent02 dark:border-bg_dark_white01 rounded-[5px] pt-[30px] px-[70px] sm:px-[20px] pb-[20px]'
                }>
                <div className={'flex flex-col items-center'}>
                    <UserThumbnail src={thumbnail} size={60} />
                    <Text className={'text-ti1 mt-[5px] text-gray01 dark:text-dark_gray01'}>{leaderName}</Text>
                </div>

                <div
                    className={
                        'mt-[20px] grid grid-cols-2 gap-y-[13.5px] border-b border-transparent02 dark:border-dark_transparent pb-[12px]'
                    }>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createLeader.step5.review.exchange.title')}
                    </Text>
                    <div className={'flex justify-end items-center'}>
                        <ExchangeTag exchange={selectKey?.leaderKey.exchange} textClassName={'text-ti1'} />
                    </div>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.createLeader.step5.review.minBalance.title')}
                    </Text>
                    <Text className={'text-right text-ti1 text-gray01 dark:text-dark_gray01'}>
                        {displayBalance(minBalance, {showPreSign: true})}
                    </Text>
                    {/*<Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>*/}
                    {/*    {t('modal.createLeader.step5.review.maxCopier.title')}*/}
                    {/*</Text>*/}
                    {/*<Text className={'text-right text-ti1'}>{displayNumber(copierCount)}</Text>*/}

                    <Text className={'col-span-2 text-body2 text-gray02 dark:text-dark_gray02 text-center'}>
                        {renderExchangeMarketText}
                    </Text>
                </div>

                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[12px]'}>
                    {t('modal.createLeader.step5.review.leaderMessage.title')}
                </Text>

                <Text className={'text-body1 text-gray01 dark:text-dark_gray01 mt-[5px] break-words'}>
                    {leaderMessage}
                </Text>
            </div>

            <div className={'mt-[30px]'}>
                <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                    {t('modal.createLeader.step5.remind.title')}
                </Text>
                <div
                    className={
                        'mt-[10px] px-[20px] py-[15px] border border-gray06 dark:border-dark_gray06 rounded-[5px]'
                    }>
                    <ul className={'list-disc px-[18px]'}>
                        <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            <TagSpan>{t('modal.createLeader.step5.remind.option1')}</TagSpan>
                        </li>
                        <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            <TagSpan>{t('modal.createLeader.step5.remind.option2')}</TagSpan>
                        </li>
                        <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            <TagSpan>{t('modal.createLeader.step5.remind.option3')}</TagSpan>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CreateLeaderReviewContainer
