import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import WizImage from '@component/images/WizImage'
import img_success_copyleader from '@image/img_success_copyleader.png'
import img_success_copyleader_dark from '@image/img_success_copyleader_dark.png'
import TagSpan from '@component/Text/TagSpan'
import {useRouter} from 'next/router'
import useDark from '@hook/useDark'

interface IProps {
    className?: string
}

const CreateLeaderDoneContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const router = useRouter()
    const {isRenderDark} = useDark()

    return (
        <div className={className}>
            <Text className={'text-h2 text-center text-gray01 dark:text-dark_gray01'}>
                {t('modal.createLeader.step6.title')}
            </Text>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 text-center'}>
                {t('modal.createLeader.step6.desc')}
            </Text>
            <WizImage
                src={isRenderDark ? img_success_copyleader_dark : img_success_copyleader}
                height={200}
                containerClassName={'mt-[30px] flex justify-center'}
            />

            <Text className={'mt-[10px] text-ti1 text-gray01 dark:text-dark_gray01'}>
                {t('modal.createLeader.step6.new.remind.title')}
            </Text>
            <div
                className={
                    'mt-[10px] bg-gray09 dark:bg-dark_gray09 border border-gray06 dark:border-dark_gray06 rounded-[5px] px-[15px] py-[20px]'
                }>
                <ul className={'list-disc px-[18px]'}>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan>{t('modal.createLeader.step6.new.remind.option1')}</TagSpan>
                    </li>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan>{t('modal.createLeader.step6.new.remind.option2')}</TagSpan>
                    </li>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan>{t('modal.createLeader.step6.new.remind.option3')}</TagSpan>
                    </li>
                </ul>

                <Text className={'mt-[20px] text-body2 text-gray02 dark:text-dark_gray02'}>
                    {t('modal.createLeader.step6.new.remind.paid')}
                </Text>
                <ul className={'list-disc px-[18px]'}>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan>{t('modal.createLeader.step6.new.remind.paid.option1')}</TagSpan>
                    </li>
                    <li className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        <TagSpan>{t('modal.createLeader.step6.new.remind.paid.option2')}</TagSpan>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CreateLeaderDoneContainer
