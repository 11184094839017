import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import WizNumberControl from '@component/inputs/WizNumberControl'

interface IProps {
    className?: string
    minBalance: number
    onChangeMinBalance: (num: number) => void
}

const CreateLeaderOptionContainer = ({className, minBalance, onChangeMinBalance}: IProps) => {
    const {t} = useTranslation()
    return (
        <div className={`${className}`}>
            <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                {t('modal.createLeader.step4.minimumBalance.title')}
            </Text>
            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                {t('modal.createLeader.step4.minimumBalance.desc')}
            </Text>
            <div
                className={
                    'flex flex-col items-center bg-gray09 dark:bg-bg_dark_white02 rounded-[5px] py-[20px] px-[30px] mt-[10px]'
                }>
                <Text className={'text-btn text-gray01 dark:text-dark_gray01'}>
                    {t('modal.createLeader.step4.minimumBalance.input.label')}
                </Text>
                <WizNumberControl
                    className={'mt-[10px]'}
                    num={minBalance}
                    stepSize={100}
                    min={200}
                    max={2000}
                    textClassName={'text-gray01 dark:text-dark_gray01'}
                    onChange={num => onChangeMinBalance(num)}
                />
                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                    {t('modal.createLeader.step4.minimumBalance.input.desc')}
                </Text>
            </div>

            {/*<Text className={'text-ti1 mt-[30px]'}>{t('modal.createLeader.step4.maximumCopiers.title')}</Text>*/}
            {/*<Text className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[5px]'}>*/}
            {/*    {t('modal.createLeader.step4.maximumCopiers.desc')}*/}
            {/*</Text>*/}
            {/*<div className={'flex flex-col items-center bg-gray09 dark:bg-dark_gray09 rounded-[5px] py-[20px] px-[30px] mt-[10px]'}>*/}
            {/*    <Text className={'text-btn'}>{t('modal.createLeader.step4.maximumCopiers.input.label')}</Text>*/}
            {/*    <WizNumberControl*/}
            {/*        className={'mt-[10px]'}*/}
            {/*        num={copierCount}*/}
            {/*        onChange={num => onChangeCopierCount(num)}*/}
            {/*        min={10}*/}
            {/*        max={50}*/}
            {/*        stepSize={1}*/}
            {/*    />*/}
            {/*    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[5px]'}>*/}
            {/*        {t('modal.createLeader.step4.maximumCopiers.input.desc')}*/}
            {/*    </Text>*/}
            {/*    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>*/}
            {/*        {t('modal.createLeader.step4.maximumCopiers.kycWarning')}*/}
            {/*    </Text>*/}
            {/*</div>*/}
        </div>
    )
}

export default React.memo(CreateLeaderOptionContainer)
